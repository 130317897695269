<template>
  <div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in images" :key="index">
        <van-image lazy-load :src="item" />
      </van-swipe-item>
    </van-swipe>
    <div class="tab">
      <van-grid :column-num="3" :gutter="10">
        <van-grid-item
          v-for="value in listS"
          :key="value.id"
          :icon="value.io"
          @click="go(value.id)"
          :text="value.name"
          class="grid-item"
        />
      </van-grid>
    </div>
    <van-popup
      v-model="show"
      :closeable="false"
      :close-on-click-overlay="false"
      position="bottom"
      :style="{
        height: '100%',
       
      }"
    >
      <div class="close">
        <span class="title-s">{{title}}</span>
        <van-icon name="close" class="icons" @click="guanbi"/>
      </div>
      <div class="content" v-html="msg"></div>
    </van-popup>
   

      <van-popup v-model="popup" :close-on-click-overlay="false">
          <van-loading type="spinner" />
          <Map ref="isMap" @loadingClose="loadingClose" />
      </van-popup>
    <!-- <div v-if="popup">
        
        <Map ref="isMap" />
    </div> -->
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload,Toast } from "vant";
import { micro_post } from "../../Http/api.js";
import Map from './components/map.vue'
import { NO1ShouQuans } from '../../utils/impower.js'

Vue.use(Lazyload);
export default {
    metaInfo(){
            return {
                 title: this.$route.query.shopId == '1078' ? '移动端拆船件' :    window.localStorage.getItem('getShopName') 
            }
        },
	components: {Map},
	data() {
		return {
		images: [
			require("../../../public/images/banner_one.png"),
			require("../../../public/images/banner_one.png"),
		],
		listS: [
			{ id: 1, name: "公司简介", io: "newspaper-o" },
			{ id: 2, name: "企业文化", io: "fire" },
			{ id: 3, name: "荣誉资质", io: "star" },
			{ id: 4, name: "产品介绍", io: "column" },
			{ id: 5, name: "地图导航", io: "location" },
			{ id: 6, name: "联系我们", io: "phone-circle" },
		],
		show: false,
		popup: false,
		msg: "",
    title:"",
		};
	},
  methods: {
    guanbi(){
         this.show = false
     
    },
    loadingClose(){
      this.popup = false
    },
     getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == variable){return pair[1];}
            }
            return(false);
    },
    go(type) {
      this.listS.forEach(i => {
          if(i.id == type){
            this.title = i.name
          }
      });
      let data = {
        url: "/index.php/BidManagement/find",
        params: {
          type: type,
          shopId: this.$route.query.shopId,
        },
      };

      switch (type) {
        case 0:
          data.success = (data) => {
           
            if(data.data.length!=0){
              this.images = data.data;
            }
            console.log(data.data.length)
          };
          break;
        case 1:
        case 2:
        case 3:
        case 4:
        case 6:
          data.success = (data) => {
            this.msg = data.data;
            this.show = true;
          };
          break;
        case 5:
          data.success = (data) => {
            this.popup = true
            this.$nextTick(()=>{
              this.$refs.isMap.weixin(data.data.lat_lng,data.data.address)
            })
          };
            break;
        default:
          break;
      }

      micro_post(data,this);
    },
  },
  async created() {
    
       const toast = Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "加载中..."
        });
        await NO1ShouQuans(this)
        toast.clear()//清除加载效果
      // this.getData()
      this.go(0);
  },
};
</script>

<style lang="scss" scoped>
.tab {
  margin-top: 30px;
  .grid-item {
    height: 3rem;
  }
}

.my-swipe {
  height: 4rem;
  .van-image {
    width: 100%;
    height: 100%;
  }
}
.close{
  position: fixed;
  width: 100%;
  height: 40px;
  background-color: #339999;
 
}
.content{
  margin-top: 20px;
  padding: 10px;
  
}
.icons{
  position: absolute;
  right: 6px;
  top: 8px;
  z-index: 99;
  font-weight: bold;
  color: #000;
  font-size: 25px;
}
.title-s{
  position: absolute;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  color: #000;
  font-size: 24px;
}
</style>

<style scoped>
 .content >>> img {
  
  max-width: 100%;
}
 .content >>> p {
    font-size: 20px;
  
}
</style>