<template>
  <div>

       <!-- <div class="map-p" id="maps" >

        </div> -->
  </div>
</template>

<script>
import { micro_post } from "../../../Http/api.js";
 import wx from 'weixin-js-sdk'
export default {
	data() {
		return {
			lat:null,
			lng:null,
		};
	},
	mounted () {
	
	},
	methods: {
		weixin(arr,ress){
			this.$http.post('/index.php/wechat/getWechatJSSignPackage',{ shopId: this.$route.query.shopId,url:window.location.href}).then(res=>{
				this.$emit('loadingClose')
				if(res.data.code == 0) {
					let signPackage = res.data.signPackage
						wx.config({ //  通过后端
							debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
							appId: signPackage.appId, // 必填，公众号的唯一标识
							timestamp: signPackage.timestamp, // 必填，生成签名的时间戳
							nonceStr: signPackage.nonceStr, // 必填，生成签名的随机串
							signature: signPackage.signature, // 必填，签名，见附录1
							jsApiList: ['openLocation'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
						}) ;
						wx.ready(function() { // 需在用户可能点击分享按钮前就先调用
						
						let la,ln = ''
					
							if(arr){
								let a = arr.split(',')
								la = a[0],
								ln = a[1]
							}else{
								Toast('未选择地址');
							}
							
							wx.openLocation({
								latitude: Number(la), // 纬度，浮点数，范围为90 ~ -90
								longitude: Number(ln), // 经度，浮点数，范围为180 ~ -180。
								name:ress, // 位置名
								address: ress, // 地址详情说明
								scale: 25, // 地图缩放级别,整形值,范围从1~28。默认为最大
								infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
							})
						})
				}
				
			})
			.catch((err)=>{
				this.$emit('loadingClose')
			})
			// const data = {
			// 	url: "/index.php/wechat/getWechatJSSignPackage",
			// 	params: { shopId: this.$route.query.shopId,url:window.location.href},
			// 	success: (res) => {
			// 		let signPackage = res.data.signPackage
			// 		wx.config({ //  通过后端
			// 			debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
			// 			appId: signPackage.appId, // 必填，公众号的唯一标识
			// 			timestamp: signPackage.timestamp, // 必填，生成签名的时间戳
			// 			nonceStr: signPackage.nonceStr, // 必填，生成签名的随机串
			// 			signature: signPackage.signature, // 必填，签名，见附录1
			// 			jsApiList: ['openLocation'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			// 		}) ;
			// 		wx.ready(function() { // 需在用户可能点击分享按钮前就先调用
			// 			this.isMap(arr)
			// 		})
			// 	}
			// };
			// micro_post(data);
			
		},
		
		initMap(la,ln) {
			TMap().then(qq => {
				var map = new qq.maps.Map(document.getElementById('maps'), {
					//这里经纬度代理表进入地图显示的中心区域
					center: new qq.maps.LatLng(la,ln),
					zoom: 17
				})
					var marker = new qq.maps.Marker({
					position:new qq.maps.LatLng(la,ln),
					map: map,
				})
			//绑定单击事件添加参数
				qq.maps.event.addListener(map, 'click', function(event) {
					// console.log(event)
					this_.lat = event.latLng.lat
					this_.lng = event.latLng.lng
					this_.initMap(this_.lat,this_.lng)
				})
				
			})
		}
	},
};
</script>

<style lang="scss" scoped>
.map-p{
  width: 100%;
  height: 100vh;
}

</style>